import { slideToggle } from '../../base/js/core'

const arrShowMore = document.querySelectorAll('.b-news__show-more-lnk')
arrShowMore.forEach(btn => {
	let arrHidden = btn.closest('.b-news').querySelectorAll('.b-news__itm_hide-phone')
	btn.addEventListener('click', e => {
		e.preventDefault()
		btn.classList.toggle('is-active')
		let txt = btn.classList.contains('is-active') ? 'Скрыть' : 'Показать еще'
		btn.querySelector('span').textContent = txt
		arrHidden.forEach(item => {
			slideToggle(item)
		})
	})
})
