import Swiper, {
	Autoplay,
	Navigation,
	Pagination
} from 'swiper'

const arrSliderMain = document.querySelectorAll('.s-main-banner')
const positionMobile = (swiper, nextEl, prevEl, pagination) => {
	let imageHeight = swiper.slides[swiper.activeIndex].querySelector('.s-main-banner__img').offsetHeight
	nextEl.style.top = `${imageHeight / 2}px`
	prevEl.style.top = `${imageHeight / 2}px`
	pagination.style.top = `${imageHeight}px`
}
arrSliderMain.forEach(section => {
	let slider = section.querySelector('.swiper')
	let prevEl = section.querySelector('.swiper-button-prev')
	let nextEl = section.querySelector('.swiper-button-next')
	let pagination = section.querySelector('.swiper-pagination')
	let options = {
		modules: [Navigation, Pagination, Autoplay],
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl,
			prevEl
		},
		autoplay: {
			delay: 5000
		},
		pagination: {
			el: pagination,
			clickable: true
		},
		on: {
			init(swiper) {
				positionMobile(swiper, nextEl, prevEl, pagination)
			},
			slideChange(swiper) {
				positionMobile(swiper, nextEl, prevEl, pagination)
			}
		}
	}
	let swiper = new Swiper(slider, options)
})
