import Swiper, {
	Navigation
} from 'swiper'
import { breakpoints } from '../../base/js/core'
const arrSubMenu = document.querySelectorAll('.main-submenu')
arrSubMenu.forEach(menu => {
	let carousel = menu.querySelector('.swiper')
	let prevEl = menu.querySelector('.swiper-button-prev')
	let nextEl = menu.querySelector('.swiper-button-next')
	let options = {
		modules: [Navigation],
		slidesPerView: 8,
		spaceBetween: 25,
		navigation: {
			nextEl,
			prevEl
		},
		on: {
			init() {
				this.el.classList.toggle('is-not-scroll', this.slides.length < this.params.slidesPerView)
			},
			breakpoint() {
				this.el.classList.toggle('is-not-scroll', this.slides.length < this.params.slidesPerView)
			}
		}
	}
	let swiper
	const swiperInit = () => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		if (menu.classList.contains('main-submenu_static')) {
			options.breakpoints = {
				320: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				390: {
					slidesPerView: 3,
					spaceBetween: 20
				},
				560: {
					slidesPerView: 4,
					spaceBetween: 20
				},
				768: {
					slidesPerView: 5,
					spaceBetween: 25
				},
				992: {
					slidesPerView: 8,
					spaceBetween: 25
				}
			}
			swiper = new Swiper(carousel, options)
		} else if (width >= breakpoints.desktop) {
			if (!carousel.classList.contains('swiper-initialized')) {
				swiper = new Swiper(carousel, options)
			}
		} else if (carousel.classList.contains('swiper-initialized')) {
			swiper.destroy()
		}
	}
	swiperInit()
	window.addEventListener('resize', swiperInit)
})
