import Swiper from 'swiper'
import { breakpoints } from '../../base/js/core'

const arrCarousel = document.querySelectorAll('.b-product-tile')
arrCarousel.forEach(carousel => {
	let slider = carousel.querySelector('.b-product-tile__swiper')
	let options = {
		slidesPerView: 1,
		spaceBetween: 20,
		breakpoints: {
			320: {
				slidesPerView: 1.5,
				spaceBetween: 5
			},
			390: {
				slidesPerView: 1.5,
				spaceBetween: 20
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 15
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 15
			}
		}
	}
	let swiper
	const swiperInit = () => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		if (width < breakpoints.lg) {
			if (!slider.classList.contains('swiper-initialized')) {
				swiper = new Swiper(slider, options)
			}
		} else if (slider.classList.contains('swiper-initialized')) {
			swiper.destroy()
		}
	}
	swiperInit()
	window.addEventListener('resize', swiperInit)
})
