import Swiper from 'swiper'

const arrCarousel = document.querySelectorAll('.b-product-carousel')
arrCarousel.forEach(carousel => {
	let slider = carousel.querySelector('.b-product-carousel__swiper')
	let options = {
		slidesPerView: 1,
		spaceBetween: 20,
		breakpoints: {
			320: {
				slidesPerView: 1.5,
				spaceBetween: 5
			},
			390: {
				slidesPerView: 1.5,
				spaceBetween: 20
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 20
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 20
			}
		}
	}
	let swiper = new Swiper(slider, options)
})
