import { getSiblings, slideToggle, slideUp } from '../../base/js/core'

const arrFaqHead = document.querySelectorAll('.js-toggle-faq')
const arrSection = document.querySelectorAll('.b-faq__section-lnk')
arrSection.forEach(lnk => {
	lnk.addEventListener('click', evt => {
		evt.preventDefault()
		lnk.parentNode.classList.toggle('is-active')
		const tabID = lnk.getAttribute('href').substr(1)
		const tab = document.getElementById(tabID)
		slideToggle(tab, 500)
	})
})
arrFaqHead.forEach(btn => {
	btn.addEventListener('click', () => {
		let parent = btn.closest('.b-faq__itm')
		let body = parent.querySelector('.b-faq__body')
		parent.classList.toggle('is-active')
		slideToggle(body)
		let siblingsItem = getSiblings(parent)
		siblingsItem.forEach(item => {
			body = item.querySelector('.b-faq__body')
			item.classList.remove('is-active')
			slideUp(body)
		})
	})
})
