import { slideToggle } from '../../base/js/core'
const filter = document.getElementById('catalog-filter')
const arrToggle = document.querySelectorAll('.b-filter__toggle')
arrToggle.forEach(btn => {
	let dropdown = btn.closest('.b-filter__item').querySelector('.b-filter__list')
	btn.addEventListener('click', () => {
		btn.classList.toggle('is-open')
		slideToggle(dropdown)
	})
})
const openFilter = document.querySelectorAll('.js-open-filter')
const closeFilter = document.querySelectorAll('.js-clse-filter')
openFilter.forEach(btn => {
	btn.addEventListener('click', () => {
		filter.classList.add('is-open')
		document.documentElement.classList.add('scroll-disabled')
	})
})
closeFilter.forEach(btn => {
	btn.addEventListener('click', () => {
		filter.classList.remove('is-open')
		document.documentElement.classList.remove('scroll-disabled')
	})
})
document.addEventListener('click', (e) => {
	if (!e.target.closest('.b-filter') && !e.target.closest('.js-open-filter') && filter) {
		filter.classList.remove('is-open')
		document.documentElement.classList.remove('scroll-disabled')
	}
})
