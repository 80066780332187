import { breakpoints, slideToggle } from '../../base/js/core'

const arrCategories = document.querySelectorAll('.b-categories__lnk')
const arrCategoriesItem = document.querySelectorAll('.b-categories__itm')
arrCategories.forEach(category => {
	let submenu = category.closest('li').querySelector('ul')
	category.addEventListener('click', e => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		if (breakpoints.tablet > width) {
			e.preventDefault()
			category.classList.toggle('is-open')
			slideToggle(submenu)
		}
	})
})

const border = () => {
	let vWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
	document.querySelectorAll('.b-categories__border').forEach(canvas => {
		canvas.remove()
	})
	if (vWidth >= breakpoints.tablet) {
		arrCategoriesItem.forEach(item => {
			let borderWidth = 1
			let canvas = document.createElement('canvas')
			let itemPosition = item.getBoundingClientRect()
			let itemHeight = Math.round(itemPosition.height) - borderWidth / 2
			let itemWidth = Math.round(itemPosition.width) - borderWidth / 2
			let gap = parseInt(getComputedStyle(item.querySelector('ul')).rowGap, 10)
			canvas.classList.add('b-categories__border')
			let itemEl = item.querySelector('.b-categories__subitm:last-child')
			let itemElPos = itemEl.getBoundingClientRect()
			let leftEl = Math.round(itemElPos.left - itemPosition.left + itemElPos.width) - borderWidth / 2
			let heightEl = itemElPos.height - borderWidth / 2
			let itemTop = Math.round(itemPosition.height - heightEl - gap - 1) - borderWidth / 2

			canvas.width = itemPosition.width
			canvas.height = itemPosition.height
			item.append(canvas)
			if (canvas.getContext) {
				let ctx = canvas.getContext('2d')
				ctx.beginPath()
				if (itemPosition.top < itemElPos.top) {
					ctx.moveTo(borderWidth / 2, borderWidth / 2)
					ctx.lineTo(itemWidth, borderWidth / 2)
					ctx.lineTo(itemWidth, itemTop)
					ctx.lineTo(leftEl, itemTop)
					ctx.lineTo(leftEl, itemHeight)
					ctx.lineTo(borderWidth / 2, itemHeight)
					ctx.lineTo(borderWidth / 2, borderWidth / 2)
				} else {
					ctx.moveTo(borderWidth / 2, borderWidth / 2)
					ctx.lineTo(leftEl, borderWidth / 2)
					ctx.lineTo(leftEl, heightEl)
					ctx.lineTo(borderWidth / 2, heightEl)
				}
				ctx.lineWidth = borderWidth
				ctx.strokeStyle = '#1b5e45'
				ctx.stroke()
			}
		})
	}
	window.addEventListener('resize', border)
}
document.addEventListener('DOMContentLoaded', () => {
	border()
})
