import { root } from '../../../.bemrc'

const buyBlock = document.getElementById('block-buy')
const buyPanel = document.getElementById('buy-panel')
if (buyBlock && buyPanel) {
	window.onload = () => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					buyPanel.classList.remove('is-active')
				} else {
					buyPanel.classList.add('is-active')
				}
			})
		}, {
			rootMargin: '-80px 0px 0px 0px'
		})
		observer.observe(buyBlock)
	}
}

