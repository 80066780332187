export const breakpoints = {
	phone: 0,
	tablet: 768,
	desktop: 992,
	lg: 1200,
	xxl: 1400
}

export let slideUp = (target, duration = 500, callback) => {
	target.style.transitionProperty = 'height, margin, padding'
	target.style.transitionDuration = `${duration}ms`
	target.style.boxSizing = 'border-box'
	target.style.height = `${target.offsetHeight}px`
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight
	target.style.overflow = 'hidden'
	target.style.height = 0
	target.style.paddingTop = 0
	target.style.paddingBottom = 0
	target.style.marginTop = 0
	target.style.marginBottom = 0
	window.setTimeout(() => {
		target.style.display = 'none'
		target.style.removeProperty('height')
		target.style.removeProperty('padding-top')
		target.style.removeProperty('padding-bottom')
		target.style.removeProperty('margin-top')
		target.style.removeProperty('margin-bottom')
		target.style.removeProperty('overflow')
		target.style.removeProperty('transition-duration')
		target.style.removeProperty('transition-property')
		if (callback) {
			callback()
		}
	}, duration)
}

export let slideDown = (target, duration = 500, callback) => {
	target.style.removeProperty('display')
	let display = target.dataset.display || window.getComputedStyle(target).display

	if (display === 'none') {
		display = 'block'
	}
	target.style.display = display
	let height = target.offsetHeight
	target.style.overflow = 'hidden'
	target.style.height = 0
	target.style.paddingTop = 0
	target.style.paddingBottom = 0
	target.style.marginTop = 0
	target.style.marginBottom = 0
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight
	target.style.boxSizing = 'border-box'
	target.style.transitionProperty = 'height, margin, padding'
	target.style.transitionDuration = `${duration}ms`
	target.style.height = `${height}px`
	target.style.removeProperty('padding-top')
	target.style.removeProperty('padding-bottom')
	target.style.removeProperty('margin-top')
	target.style.removeProperty('margin-bottom')
	window.setTimeout(() => {
		target.style.removeProperty('height')
		target.style.removeProperty('overflow')
		target.style.removeProperty('transition-duration')
		target.style.removeProperty('transition-property')
		if (callback) {
			callback()
		}
	}, duration)
}

export let slideToggle = (target, duration = 500, callback) => {
	let display = window.getComputedStyle(target).display
	if (display === 'none') {
		slideDown(target, duration, callback)
	} else {
		slideUp(target, duration, callback)
	}
}
const getChildren = (n, skipMe) => {
	let r = []
	for (; n; n = n.nextSibling) {
		if (n.nodeType === 1 && n !== skipMe) {
			r.push(n)
		}
	}

	return r
}
export const getSiblings = (n) => {
	return getChildren(n.parentNode.firstChild, n)
}
export const prevAll = (element) => {
	const result = []
	while (element = element.previousElementSibling) {
		result.push(element)
	}

	return result
}
export const validate = (selector) => {
	const inputsValidate = document.querySelectorAll(selector)
	inputsValidate.forEach($input => {
		$input.addEventListener('invalid', (e) => {
			e.preventDefault()
			$input.classList.add('is-invalid')
			$input.closest('.radio').classList.add('is-invalid')
		})
		$input.addEventListener('input', () => {
			$input.classList.remove('is-invalid')
			if ($input.getAttribute('type') === 'radio') {
				const radios = $input.closest('form').querySelectorAll(`input[name="${$input.getAttribute('name')}"]`)
				radios.forEach($radio => {
					$radio.classList.remove('is-invalid')
					$radio.closest('.radio').classList.remove('is-invalid')
				})
			}
		})
	})
}
validate('.js-validate [required]')
import inputmask from 'inputmask'
const $inputPhones = document.querySelectorAll('input[type="tel"]')
$inputPhones.forEach($input => {
	inputmask({
		mask: '+7 ( 999 ) 999 - 99 - 99',
		showMaskOnHover: false
	}).mask($input)
})
const anchors = document.querySelectorAll('.js-anchore')
anchors.forEach($button => {
	$button.addEventListener('click', (e) => {
		e.preventDefault()
		const $el = document.getElementById($button.getAttribute('href').substring(1))
		if ($el) {
			const headerH = document.querySelector('.s-header').offsetHeight
			let display = window.getComputedStyle($el).display
			if (display === 'none') {
				$el.style.display = 'block'
			}
			const elPosition = $el.getBoundingClientRect().top - headerH - 45
			if (display === 'none') {
				$el.style.display = 'none'
			}
			window.scrollBy({
				top: elPosition,
				behavior: 'smooth'
			})
		}
	})
})
const tabs = (selector) => {
	const buttons = document.querySelectorAll(selector)
	buttons.forEach($button => {
		const tabID = $button.dataset.tab.substr(1) || $button.getAttribute('href').substr(1)
		const $tab = document.getElementById(tabID)
		const sBtns = getSiblings($button.parentNode)

		const sTabs = getSiblings($tab)
		$button.addEventListener('click', (e) => {
			e.preventDefault()
			$button.classList.add('is-active')
			$tab.classList.add('is-visible')
			sBtns.forEach($sBtn => {
				if ($sBtn.querySelector('[data-tab]')) {
					$sBtn.querySelector('[data-tab]').classList.remove('is-active')
				}
			})
			sTabs.forEach($sTab => {
				$sTab.classList.remove('is-visible')
			})
		})
	})
}
tabs('[data-tab]')
const fieldTextarea = () => {
	const arrTextarea = document.querySelectorAll('.js-vertical')
	const empty = (input) => {
		if (input.innerText === '') {
			input.style.lineHeight = '118px'
		} else {
			input.removeAttribute('style')
			input.parentNode.querySelector('textarea').classList.remove('is-invalid')
		}
	}
	arrTextarea.forEach(textarea => {
		let input = textarea.parentNode.querySelector('.input__instead')
		textarea.value = input.innerText
		empty(input)
		input.addEventListener('keyup', () => {
			textarea.value = input.innerText
			empty(input)
		}, false)
		input.addEventListener('keydown', () => {
			empty(input)
		}, false)
	})
}
fieldTextarea()
const elmsRemoveClass = (elms, cl) => {
	elms.forEach(el => {
		el.classList.remove(cl)
	})
}
const selects = document.querySelectorAll('.select')
selects.forEach($select => {
	const $input = $select.querySelector('input')
	const items = $select.querySelectorAll('.select__itm')
	const $headTxt = $select.querySelector('.select__txt')
	const $dropdown = $select.querySelector('.select__dropdown')

	items.forEach($itm => {
		$itm.addEventListener('click', () => {
			const siblings = getSiblings($itm)
			elmsRemoveClass(siblings, 'is-selected')
			$itm.classList.add('is-selected')
			$input.value = $itm.textContent
			$input.classList.remove('is-invalid')
			$headTxt.textContent = $itm.textContent
			$select.classList.remove('is-open')
			$select.classList.remove('select_has-placeholder')
			slideUp($dropdown)
		})
	})

	$select.querySelector('.select__head').addEventListener('click', () => {
		$select.classList.toggle('is-open')
		if ($select.classList.contains('is-open')) {
			slideDown($dropdown)
		} else {
			slideUp($dropdown)
		}
	})
	document.addEventListener('click', (e) => {
		if (!e.target.closest('.select')) {
			selects.forEach($selectC => {
				if ($selectC.classList.contains('is-open')) {
					$selectC.classList.remove('is-open')
					slideUp($selectC.querySelector('.select__dropdown'))
				}
			})
		}
	})
})

const sort = () => {
	const arr = document.querySelectorAll('.sort')
	arr.forEach(box => {
		let btn = box.querySelector('.sort__head')
		let arrItm = box.querySelectorAll('.sort__itm')
		let input = box.querySelector('.sort__input')
		let ttl = box.querySelector('.sort__ttl')
		btn.addEventListener('click', () => {
			box.classList.add('is-open')
		})
		arrItm.forEach(itm => {
			itm.addEventListener('click', () => {
				arrItm.forEach(el => {
					el.classList.remove('is-selected')
				})
				itm.classList.add('is-selected')
				box.classList.remove('is-open')
				box.querySelector('.sort__input').value = 'a'
				input.value = itm.textContent
				let event = new Event('change')
				input.dispatchEvent(event)
				ttl.textContent = itm.textContent
			})
		})
	})
	document.addEventListener('click', (e) => {
		if (!e.target.closest('.sort')) {
			arr.forEach($box => {
				if ($box.classList.contains('is-open')) {
					$box.classList.remove('is-open')
				}
			})
		}
	})
}
sort()
const chars = () => {
	const arrBtnShowAll = document.querySelectorAll('.js-chars-all')
	arrBtnShowAll.forEach(btn => {
		let arrHidden = btn.closest('.chars').querySelectorAll('.is-hidden')
		btn.addEventListener('click', (e) => {
			e.preventDefault()
			let txt = !btn.classList.contains('is-active') ? 'Скрыть' : 'Все характеристики'
			btn.classList.toggle('is-active')
			btn.textContent = txt
			arrHidden.forEach(itm => {
				slideToggle(itm)
			})
		})
	})
}
chars()
