import Swiper, { Pagination } from 'swiper';

const arrCarousel = document.querySelectorAll('.b-card');
arrCarousel.forEach(carousel => {
	let slider = carousel.querySelector('.swiper');
	let pagination = carousel.querySelector('.swiper-pagination');
	let options = {
		modules: [Pagination],
		slidesPerView: 1,
		loop: true,
		spaceBetween: 0,
		pagination: {
			el: pagination,
			clickable: true
		}
	}
	let swiper = new Swiper(slider, options);
})
