import Swiper, {
	Pagination
} from 'swiper'

const arrSliderDesc = document.querySelectorAll('.slider-desc')
arrSliderDesc.forEach(section => {
	let slider = section.querySelector('.swiper')
	let pagination = section.querySelector('.swiper-pagination')
	let options = {
		modules: [Pagination],
		slidesPerView: 1,
		spaceBetween: 0,

		pagination: {
			el: pagination,
			clickable: true
		}
	}
	let swiper = new Swiper(slider, options)
})
