import './s-header/s-header'
import './main-menu/main-menu'
import './main-submenu/main-submenu'
import './footer-catalog/footer-catalog'
import './s-main-banner/s-main-banner'
import './s-advantages/s-advantages'
import './b-card/b-card'
import './b-product-carousel/b-product-carousel'
import './b-product-tile/b-product-tile'
import './b-categories/b-categories'
import './b-news/b-news'
import './b-blog/b-blog'
import './b-popup/b-popup'
import './b-filter/b-filter'
import './b-slider/b-slider'
import './video/video'
import './b-buy/b-buy'
import './technologies/technologies'
import './slider-pict/slider-pict'
import './slider-desc/slider-desc'
import './b-faq/b-faq'
import './menu/menu'
import './advantages/advantages'

