import Swiper, {
	Navigation
} from 'swiper'
const arrSection = document.querySelectorAll('.technologies')
const activeSlide = (sw, swiper) => {
	swiper.slides.forEach(slide => {
		slide.classList.remove('is-current')
	})
	let index = sw.slides[sw.activeIndex].dataset.swiperSlideIndex
	if (swiper.slides[index]) {
		swiper.slides[index].classList.add('is-current')
	}
}
arrSection.forEach(section => {
	let slider = section.querySelector('.js-slider')
	let thumbs = section.querySelector('.js-thumbs')
	let arrThumbsSlide = thumbs.querySelectorAll('.swiper-slide')
	let prevThumbsEl = section.querySelector('.js-thumbs-prev')
	let nextThumbsEl = section.querySelector('.js-thumbs-next')
	let swiper = new Swiper(thumbs, {
		modules: [Navigation],
		spaceBetween: 20,
		slidesPerView: 'auto',
		navigation: {
			nextEl: nextThumbsEl,
			prevEl: prevThumbsEl
		},
		watchSlidesProgress: true,
		breakpoints: {
			320: {
				slidesPerView: 'auto',
				spaceBetween: 10
			},
			768: {
				slidesPerView: 'auto',
				spaceBetween: 20
			}
		}
	})

	let swiper2 = new Swiper(slider, {
		loop: true,
		centeredSlides: true,
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 20
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 20
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 20
			}
		},
		on: {
			afterInit(sw) {
				activeSlide(sw, swiper)
			}
		}
	})
	swiper2.on('slideChange', () => {
		activeSlide(swiper2, swiper)
	})
	arrThumbsSlide.forEach((slide, i) => {
		slide.addEventListener('click', () => {
			swiper2.slides.forEach((item, n) => {
				if (item.dataset.swiperSlideIndex * 1 === i) {
					swiper2.slideTo(n)
				}
			})
		})
	})
})
