import Swiper, {
	Navigation,
	Pagination,
	Thumbs
} from 'swiper'
const arrSlider = document.querySelectorAll('.b-slider')
arrSlider.forEach(sliderB => {
	let slider = sliderB.querySelector('.js-slider')
	let thumbs = sliderB.querySelector('.js-thumbs')
	let prevThumbsEl = sliderB.querySelector('.js-thumbs-prev')
	let nextThumbsEl = sliderB.querySelector('.js-thumbs-next')
	let prevEl = sliderB.querySelector('.js-prev')
	let nextEl = sliderB.querySelector('.js-next')
	let pagination = sliderB.querySelector('.swiper-pagination')
	let swiper = new Swiper(thumbs, {
		modules: [Navigation],
		direction: 'vertical',
		loop: true,
		spaceBetween: 10,
		slidesPerView: 5,
		navigation: {
			nextEl: nextThumbsEl,
			prevEl: prevThumbsEl
		},
		watchSlidesProgress: true
	})
	let swiper2 = new Swiper(slider, {
		modules: [Thumbs, Pagination, Navigation],
		loop: true,
		pagination: {
			el: pagination,
			clickable: true
		},
		navigation: {
			nextEl,
			prevEl
		},

		thumbs: {
			swiper
		}
	})
})
