const arrVideo = document.querySelectorAll('.video')
if (arrVideo.length) {
	let tag = document.createElement('script')
	tag.src = 'https://www.youtube.com/iframe_api'
	let firstScriptTag = document.getElementsByTagName('script')[0]
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}
arrVideo.forEach(video => {
	let btn = video.querySelector('.video__play')
	let idPlayer = video.dataset.youtubeId
	let player
	if (idPlayer) {
		window.onYouTubeIframeAPIReady = () => {
			player = new YT.Player('player', {
				videoId: idPlayer
			})
		}
		btn.addEventListener('click', e => {
			e.preventDefault()
			player.playVideo()
			video.classList.add('is-active')
		})
	}
})
