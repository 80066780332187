import Swiper, { Navigation, Pagination } from 'swiper'

const arrAdv = document.querySelectorAll('.advantages')
arrAdv.forEach(adv => {
	let slider = adv.querySelector('.swiper')
	let prevEl = adv.querySelector('.swiper-button-prev')
	let nextEl = adv.querySelector('.swiper-button-next')
	let pagination = adv.querySelector('.swiper-pagination')
	let options = {
		modules: [Navigation, Pagination],
		slidesPerView: 1,
		spaceBetween: 20,
		navigation: {
			nextEl,
			prevEl
		},
		pagination: {
			el: pagination,
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 0
			},

			768: {
				slidesPerView: 2,
				spaceBetween: 20
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 20
			}
		}
	}
	let swiper = new Swiper(slider, options)
})
