import Swiper, { Navigation, Pagination } from 'swiper'
import { breakpoints } from '../../base/js/core'

const arrBlog = document.querySelectorAll('.b-blog')
arrBlog.forEach(blog => {
	if (blog.classList.contains('b-blog_has-carousel')) {
		let slider = blog.querySelector('.swiper')
		let prevEl = blog.querySelector('.swiper-button-prev')
		let nextEl = blog.querySelector('.swiper-button-next')
		let pagination = blog.querySelector('.swiper-pagination')
		let options = {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			spaceBetween: 20,
			navigation: {
				nextEl,
				prevEl
			},
			pagination: {
				el: pagination,
				clickable: true
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 0
				},

				768: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				992: {
					slidesPerView: 3,
					spaceBetween: 20
				}
			}
		}
		let swiper
		const swiperInit = () => {
			const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
			if (width <= breakpoints.xxl) {
				if (!slider.classList.contains('swiper-initialized')) {
					swiper = new Swiper(slider, options)
				}
			} else if (slider.classList.contains('swiper-initialized')) {
				swiper.destroy()
			}
		}
		swiperInit()
		window.addEventListener('resize', swiperInit)
	}
})
