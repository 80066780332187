import { breakpoints } from '../../base/js/core'
const toggleMainMenu = document.querySelector('.js-main-menu-toggle')
const mainMenu = document.querySelector('.main-menu')
toggleMainMenu.addEventListener('click', e => {
	e.preventDefault()
	toggleMainMenu.classList.toggle('is-active')
	mainMenu.classList.toggle('is-visible')
	document.documentElement.classList.toggle('scroll-disabled')
})
const headerHeight = () => {
	const header = document.querySelector('.s-header')
	if (header) {
		header.style.height = 'auto'
		let height = header.querySelector('.s-header__in').offsetHeight
		if (document.querySelector('.main-submenu_static')) {
			height += document.querySelector('.main-submenu_static').offsetHeight
		}
		header.style.height = `${height}px`
	}
}
window.addEventListener('resize', headerHeight)
headerHeight()
document.addEventListener('click', (e) => {
	if (!e.target.closest('.main-menu') && !e.target.closest('.js-main-menu-toggle')) {
		toggleMainMenu.classList.remove('is-active')
		mainMenu.classList.remove('is-visible')
		document.documentElement.classList.remove('scroll-disabled')
	}
})
const mainSubmenuStatic = document.querySelector('.main-submenu_static')
if (mainSubmenuStatic) {
	let oldScrTop = 0
	const hideMainSubMenu = () => {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		if (width < breakpoints.tablet) {
			let headerH = document.querySelector('.s-header').offsetHeight
			let scrTop = document.documentElement.scrollTop
			let cardFirstTop = document.querySelector('.b-catalog').firstElementChild.getBoundingClientRect().top + scrTop + 35

			if (oldScrTop < scrTop && scrTop > cardFirstTop - headerH) {
				mainSubmenuStatic.style.transform = 'translateY(-100%)'
			} else {
				mainSubmenuStatic.style.transform = 'translateY(0%)'
			}
			oldScrTop = scrTop
		} else {
			mainSubmenuStatic.style.transform = 'translateY(0%)'
		}
	}
	hideMainSubMenu()
	window.addEventListener('scroll', () => {
		hideMainSubMenu()
	})
	window.addEventListener('resize', hideMainSubMenu())
}
