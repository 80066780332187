import { breakpoints, getSiblings, slideToggle, slideUp } from '../../base/js/core';
const arrToggleSubMenu = document.querySelectorAll('.js-footer-submenu');
const slideUpArrMenu = (arr) => {
	arr.forEach(item => {
		let menu = item.querySelector('.footer-catalog__submenu');
		item.classList.remove('is-open');
		slideUp(menu);
	})
}
arrToggleSubMenu.forEach(toggle => {
	let submenu = toggle.querySelector('.footer-catalog__submenu');
	let toggleMobile = toggle.querySelector('.footer-catalog__lnk');
	toggleMobile.addEventListener('click', e => {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		e.preventDefault();
		if (width <= breakpoints.desktop && e.target.classList.contains('footer-catalog__toggle')) {
			toggle.classList.toggle('is-open');
			slideToggle(submenu);
			let arrSibligns = getSiblings(toggle.closest('.footer-catalog__itm'));
			slideUpArrMenu(arrSibligns);
		}
	})
});
