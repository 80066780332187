import Swiper, {
	Scrollbar
} from 'swiper'

const arrSliderMain = document.querySelectorAll('.s-advantages')
arrSliderMain.forEach(section => {
	let slider = section.querySelector('.swiper')
	let scrollbar = section.querySelector('.swiper-scrollbar')
	let options = {
		modules: [Scrollbar],
		slidesPerView: 3,
		spaceBetween: 10,
		scrollbar: {
			el: scrollbar,
			draggable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 10
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 30
			},
			992: {
				slidesPerView: 2,
				spaceBetween: 30
			},
			1200: {
				slidesPerView: 3,
				spaceBetween: 40
			},
			1400: {
				slidesPerView: 3,
				spaceBetween: 86
			}
		}
	}
	let swiper = new Swiper(slider, options)
})
