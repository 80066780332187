import {
	breakpoints,
	getSiblings,
	slideToggle,
	slideUp
} from '../../base/js/core';

const arrToggleSubMenu = document.querySelectorAll('.js-submenu');
const mainMenu = document.querySelector('.main-menu');
let flagSubMenu = false;
let flagSubMenuVisible = false;
const slideUpArrMenu = (arr) => {
	arr.forEach(item => {
		let menu = item.querySelector('.main-submenu');
		item.classList.remove('is-open');
		slideUp(menu);
	})
}
arrToggleSubMenu.forEach(toggle => {
	let submenu = toggle.querySelector('.main-submenu');
	let toggleMobile = toggle.querySelector('.main-menu__lnk');
	toggle.addEventListener('mouseenter', () => {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width > breakpoints.desktop) {
			flagSubMenu = true;
			if (flagSubMenuVisible) {
				submenu.style.transitionDuration = '0s';
				submenu.classList.add('is-visible');
			} else {
				setTimeout(() => {
					if (flagSubMenu) {
						submenu.removeAttribute('style');
						submenu.classList.add('is-visible');
					}
				}, 250)
			}
			flagSubMenuVisible = true;
		}
	});
	toggle.addEventListener('mouseleave', () => {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width > breakpoints.desktop) {
			submenu.style.transitionDuration = '0.3s';
			submenu.classList.remove('is-visible');
			flagSubMenu = false;
		}
	});
	toggleMobile.addEventListener('click', e => {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width <= breakpoints.desktop && e.target.classList.contains('main-menu__toggle')) {
			e.preventDefault();
			toggle.classList.toggle('is-open');
			slideToggle(submenu);
			let arrSibligns = getSiblings(toggle.closest('.main-menu__itm'));
			slideUpArrMenu(arrSibligns);
		}
	})
});
mainMenu.addEventListener('mouseleave', () => {
	flagSubMenuVisible = false;
})
