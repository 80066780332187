import { Fancybox } from '@fancyapps/ui'

const options = {
	closeButton: false,
	showClass: () => {
	},
	hideClass: () => {

	},
	on: {
		init: () => {
			if (document.querySelector('.fancybox__container .b-popup__close')) {
				document.querySelector('.fancybox__container .b-popup__close').click()
			}
		}
	}
}

document.addEventListener('click', (e) => {
	if (!e.target.closest('.b-popup') && !e.target.closest('[data-fancybox]')) {
		Fancybox.close()
	}
})
Fancybox.bind('[data-fancybox]', options)
