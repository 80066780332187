import Swiper, {
	Navigation,
	Pagination
} from 'swiper'
import { breakpoints } from '../../base/js/core'

const arrSlider = document.querySelectorAll('.slider-pict')

arrSlider.forEach(section => {
	let slider = section.querySelector('.swiper')
	let prevEl = section.querySelector('.swiper-button-prev')
	let nextEl = section.querySelector('.swiper-button-next')
	let pagination = section.querySelector('.swiper-pagination')
	const position = (sw) => {
		let height = 0
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		sw.slides.forEach(item => {
			let pictH = item.querySelector('.slider-pict__pict').offsetHeight
			if (height < pictH) {
				height = pictH
			}
		})
		if (width > breakpoints.tablet) {
			prevEl.style.top = `${height / 2}px`
			nextEl.style.top = `${height / 2}px`
			pagination.style.top = 'auto'
		} else {
			prevEl.style.top = `${height}px`
			nextEl.style.top = `${height}px`
			pagination.style.top = `${height}px`
		}
	}
	let options = {
		modules: [Navigation, Pagination],
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl,
			prevEl
		},
		pagination: {
			el: pagination,
			clickable: true
		},
		on: {
			afterInit(sw) {
				position(sw)
			},
			resize(sw) {
				position(sw)
			}
		}
	}
	let swiper = new Swiper(slider, options)
})
